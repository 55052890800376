import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#2962FF',
                secondary: '#363636',
            }
        }
    },
    icons: {
        iconfont: 'fa',
        values: {
            checkboxOn: 'fal fa-check-square',
            checkboxOff: 'fal fa-square',
            dropdown: 'fal fa-angle-down',
            subgroup: 'fal fa-angle-down',
            expand: 'fal fa-angle-down',
            close: 'fal fa-times',
            minus: 'fal fa-minus',
            sort: 'fas fa-arrow-alt-circle-up',
            complete: 'fal fa-check',
            cancel: 'fal fa-times-circle',
            delete: 'fal fa-times-circle', // delete (e.g. v-chip close)
            clear: 'fal fa-times-circle', // delete (e.g. v-chip close)
            success: 'fal fa-check-circle',
            info: 'fal fa-info-circle',
            warning: 'fal fa-exclamation',
            error: 'fal fa-exclamation-triangle',
            prev: 'fal fa-angle-left',
            next: 'fal fa-angle-right',
            checkboxIndeterminate: 'fal fa-minus-square',
            delimiter: 'fal fa-circle', // for carousel
            menu: 'fal fa-bars',
            radioOn: 'far fa-dot-circle',
            radioOff: 'far fa-circle',
            edit: 'fal fa-edit',
            ratingEmpty: 'far fa-star',
            ratingFull: 'fal fa-star',
            ratingHalf: 'fal fa-star-half',
            loading: 'fal fa-sync',
            first: 'fal fa-step-backward',
            last: 'fal fa-step-forward',
            unfold: 'fal fa-arrows-alt-v',
            file: 'fal fa-paperclip',
            plus: 'fal fa-plus',
        }
    }
});
