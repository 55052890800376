export default {
    path: '/tickets',
    component: () => import('./index.vue'),
    meta: {
        label: 'Tickets',
        icon: 'fal fa-clipboard-list fa-fw',
        location: 'sidebar-top',
        permission: 'manage-customer-tickets',
        auth: true,
        menuItem: () => import('./components/menu-item.vue'),
    },
    children: [
        {
            path: '',
            name: 'tickets.index',
            component: () => import('./tickets.vue'),
            meta: {
                label: 'Tickets',
                auth: true,
                permission: 'manage-customer-tickets'
            }
        },
        {
            path: ':ticketId/details',
            name: 'tickets.details',
            component: () => import('./details.vue'),
            meta: {
                label: 'Ticket Details',
                auth: true,
                permission: 'manage-customer-tickets',
                parent: 'tickets.index',
            }
        },
    ]
}
