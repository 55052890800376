export default {
    path: '/assets',
    component: () => import('./Index.vue'),
    meta: {
        label: 'Assets',
        icon: 'fal fa-clipboard-list fa-fw',
        location: 'sidebar-top',
        permission: 'manage-customer-assets',
        auth: true,
        menuItem: () => import('./components/MenuItem.vue'),
    },
    children: [
        {
            path: '',
            name: 'assets.index',
            component: () => import('./Assets.vue'),
            meta: {
                label: 'Assets',
                auth: true,
                permission: 'manage-customer-assets'
            }
        },
        {
            path: 'create',
            name: 'assets.create',
            component: () => import('./Assets.vue'),
            meta: {
                label: 'Create Asset',
                auth: true,
                permission: 'manage-customer-assets'
            }
        },
        {
            path: ':assetId/details',
            name: 'assets.details',
            component: () => import('./Assets.vue'),
            meta: {
                label: 'Asset Details',
                auth: true,
                permission: 'manage-customer-assets',
                parent: 'assets.index',
            }
        },
        {
            path: ':assetId/edit',
            name: 'assets.edit',
            component: () => import('./Assets.vue'),
            meta: {
                label: 'Edit Asset',
                auth: true,
                permission: 'manage-customer-assets',
                parent: 'assets.index',
            }
        },
        {
            path: ':assetId/secrets',
            name: 'assets.secrets',
            component: () => import('./Assets.vue'),
            meta: {
                label: 'Asset Secrets',
                auth: true,
                permission: 'manage-customer-assets',
                parent: 'assets.index',
            },
            children: [
                {
                    path: 'create',
                    name: 'assets.secrets.create',
                    component: () => import('./Assets.vue'),
                    meta: {
                        label: 'Create Asset Secret',
                        auth: true,
                        permission: 'manage-customer-assets',
                        parent: 'assets.secrets',
                    }
                },
                {
                    path: ':secretId/edit',
                    name: 'assets.secrets.edit',
                    component: () => import('./Assets.vue'),
                    meta: {
                        label: 'Edit Asset Secret',
                        auth: true,
                        permission: 'manage-customer-assets',
                        parent: 'assets.secrets',
                    }
                },
                {
                    path: ':secretId/details',
                    name: 'assets.secrets.details',
                    component: () => import('./Assets.vue'),
                    meta: {
                        label: 'Asset Secret Details',
                        auth: true,
                        permission: 'manage-customer-assets',
                        parent: 'assets.secrets',
                    }
                },
            ]
        },
    ]
}
