<template>
    <v-app>
        <app-bar class="dont-print" />
        <v-main class="tw-bg-gray-300">
            <v-container fluid class="tw-bg-gray-300 tw-p-0 tw-h-full">
                <router-view />
            </v-container>
        </v-main>
        <snackbar ref="snackbar" />
        <loading ref="loading" />
        <confirm ref="confirm" />
        <v-snackbar v-model="updateExists" :timeout="-1" top right color="white" light>
            <div class="d-flex align-center">
                <v-icon dark color="orange">fal fa-bell-on fa-fw</v-icon>
                <div class="ml-4">New version available.</div>
            </div>
            <template v-slot:action="{ attrs }">
                <v-btn color="primary" small v-bind="attrs" @click="refreshApp"> Refresh </v-btn>
            </template>
        </v-snackbar>
    </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import AppBar from "@/components/AppBar.vue";
import Snackbar from "@/components/Snackbar.vue";
import Loading from "@/components/Loading.vue";
import Confirm from "@/components/Confirm.vue";

export default {
    name: "App",
    components: {
        Snackbar,
        Loading,
        AppBar,
        Confirm,
    },
    data() {
        return {
            refreshing: false,
            registration: null,
            updateExists: false,
        };
    },
    computed: {
        ...mapGetters(["authenticated"]),
    },
    created() {
        document.addEventListener("swUpdated", this.showRefreshUI, { once: true });

        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener("controllerchange", () => {
                if (this.refreshing) return;
                this.refreshing = true;
                window.location.reload();
            });
        }
    },
    mounted() {
        this.$root.$snackbar = this.$refs.snackbar;
        this.$root.$loading = this.$refs.loading;
        this.$root.$confirm = this.$refs.confirm.open;
    },
    methods: {
        showRefreshUI(e) {
            this.registration = e.detail;
            this.updateExists = true;
        },
        refreshApp() {
            this.updateExists = false;
            if (!this.registration || !this.registration.waiting) {
                return;
            }
            this.registration.waiting.postMessage("skipWaiting");
        },
    },
};
</script>
