import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Store from "@/store";
import Http from "@/utils/httpClient";
import TicketRoutes from "@/views/ticket/routes";
import AssetsRoutes from "@/views/assets/routes";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
            auth: true,
            label: "Dashboard",
            location: "sidebar-top",
            group: "main",
            icon: "fal fa-tachometer-alt fa-fw"
        }
    },
    {
        path: "/company",
        name: "company",
        component: () => import("@/views/company/Index.vue"),
        children: [
            {
                path: "contacts",
                name: "customer.contacts.index",
                component: () => import("@/views/company/contact/Index.vue"),
                meta: {
                    auth: true,
                    label: "Contacts",
                    location: "sidebar",
                    parent: "company",
                    permission: "manage-customer-contacts"
                },
                children: [
                    {
                        path: "create",
                        name: "customer.contacts.create",
                        component: () => import("@/views/company/contact/Index.vue"),
                        meta: {
                            auth: true,
                            label: "Create Contact",
                            location: "sidebar",
                            parent: "customer.contacts.index",
                            permission: "manage-customer-contacts"
                        }
                    },
                    {
                        path: ":contactId/edit",
                        name: "customer.contacts.edit",
                        component: () => import("@/views/company/contact/Index.vue"),
                        meta: {
                            auth: true,
                            label: "Edit Contact",
                            location: "sidebar",
                            parent: "customer.contacts.index",
                            permission: "manage-customer-contacts"
                        }
                    }
                ]
            },
            {
                path: "orders",
                name: "customer.orders.index",
                component: () => import("@/views/company/order/Index.vue"),
                meta: {
                    auth: true,
                    label: "Orders",
                    location: "sidebar",
                    parent: "company",
                    permission: "manage-customer-orders"
                },
                children: [
                    {
                        path: "create",
                        name: "customer.orders.create",
                        component: () => import("@/views/company/order/Index.vue"),
                        meta: {
                            auth: true,
                            label: "Create Order",
                            location: "sidebar",
                            parent: "customer.orders.index",
                            permission: "manage-customer-orders"
                        }
                    },
                    {
                        path: ":orderId/edit",
                        name: "customer.orders.edit",
                        component: () => import("@/views/company/order/Index.vue"),
                        meta: {
                            auth: true,
                            label: "Edit Order",
                            location: "sidebar",
                            parent: "customer.orders.index",
                            permission: "manage-customer-orders"
                        }
                    }
                ]
            },
            {
                path: "sites",
                name: "customer.sites.index",
                component: () => import("@/views/company/site/Index.vue"),
                meta: {
                    auth: true,
                    label: "Sites",
                    location: "sidebar",
                    parent: "company",
                    permission: "manage-customer-sites"
                },
                children: [
                    {
                        path: "create",
                        name: "customer.sites.create",
                        component: () => import("@/views/company/site/Index.vue"),
                        meta: {
                            auth: true,
                            label: "Create Site",
                            location: "sidebar",
                            parent: "customer.sites.index",
                            permission: "manage-customer-sites"
                        }
                    },
                    {
                        path: ":siteId/edit",
                        name: "customer.sites.edit",
                        component: () => import("@/views/company/site/Index.vue"),
                        meta: {
                            auth: true,
                            label: "Create Site",
                            location: "sidebar",
                            parent: "customer.sites.index",
                            permission: "manage-customer-sites"
                        }
                    }
                ]
            }
        ],
        meta: {
            name: "company",
            auth: true,
            label: "Company",
            location: "sidebar-top",
            group: "main",
            icon: "fal fa-building fa-fw"
        }
    },
    AssetsRoutes,
    TicketRoutes,
    {
        path: "/services",
        name: "services",
        component: () => import("@/views/services/Index.vue"),
        meta: {
            name: "services",
            auth: true,
            label: "Services",
            location: "sidebar-top",
            group: "main",
            icon: "fal fa-cogs fa-fw",
            breadcrumb: "Services"
        },
        children: [
            {
                path: "internet",
                name: "services.internet",
                component: () => import("@/views/services/Internet.vue"),
                meta: {
                    auth: true,
                    label: "Internet",
                    breadcrumb: "Internet",
                    icon: "mdi-microsoft-internet-explorer",
                    location: "sidebar",
                    parent: "services",
                    permission: "manage-customer-services"
                }
            },
            {
                path: "hosting",
                name: "services.hosting",
                component: () => import("@/views/services/Hosting.vue"),
                meta: {
                    auth: true,
                    label: "Hosting",
                    breadcrumb: "Hosting",
                    icon: "mdi-server-security",
                    location: "sidebar",
                    parent: "services",
                    permission: "manage-customer-services"
                }
            },
            {
                path: "domains",
                name: "services.domains",
                component: () => import("@/views/services/Domains.vue"),
                meta: {
                    auth: true,
                    label: "Domains",
                    breadcrumb: "Domains",
                    icon: "mdi-web",
                    location: "sidebar",
                    parent: "services",
                    permission: "manage-customer-services"
                }
            },
            {
                path: "voice",
                name: "services.voice",
                component: () => import("@/views/services/Voice.vue"),
                meta: {
                    auth: true,
                    label: "Voice",
                    breadcrumb: "Voice",
                    icon: "mdi-microphone",
                    location: "sidebar",
                    parent: "services",
                    permission: "manage-customer-services"
                },
                children: [
                    {
                        path: "voice/:serviceId/usage",
                        name: "services.voice.usage",
                        component: () => import("@/views/services/Voice.vue"),
                        meta: {
                            auth: true,
                            label: "Voice Usage",
                            parent: "services.voice",
                            permission: "manage-customer-services"
                        }
                    }
                ]
            },
            {
                path: "miscellaneous",
                name: "services.misc",
                component: () => import("@/views/services/Misc.vue"),
                meta: {
                    auth: true,
                    label: "Misc",
                    breadcrumb: "Miscellaneous",
                    icon: "mdi-folder-open-outline",
                    location: "sidebar",
                    parent: "services",
                    permission: "manage-customer-services"
                }
            }
        ]
    },
    {
        path: "/security",
        name: "security",
        component: () => import("@/views/security/Index.vue"),
        children: [
            {
                path: "rss/:rssId",
                name: "security.rss.index",
                component: () => import("@/views/rss/Index.vue"),
                meta: {
                    auth: true,
                    label: "RSS Feed",
                    location: "sidebar",
                    parent: "security",
                    permission: "manage-customer-security"
                }
            }
        ],
        meta: {
            name: "security",
            auth: true,
            label: "Security",
            location: "sidebar-top",
            group: "main",
            icon: "fal fa-shield-check fa-fw"
        }
    },
    {
        path: "/finance",
        name: "finance",
        component: () => import("@/views/billing/Index.vue"),
        children: [
            {
                path: "invoices",
                name: "finance.invoices.index",
                component: () => import("@/views/billing/invoice/Index.vue"),
                meta: {
                    auth: true,
                    label: "Invoices",
                    location: "sidebar",
                    parent: "finance",
                    permission: "manage-customer-invoices"
                }
            },
            {
                path: "payments",
                name: "finance.payments.index",
                component: () => import("@/views/billing/payment/Index.vue"),
                meta: {
                    auth: true,
                    label: "Receipts",
                    location: "sidebar",
                    parent: "finance",
                    permission: "manage-customer-payments"
                }
            },
            {
                path: "pay",
                name: "finance.pay",
                component: () => import("@/views/billing/Pay.vue"),
                meta: {
                    auth: true,
                    label: "Make Payment",
                    location: "sidebar",
                    parent: "finance",
                    permission: "manage-customer-payments"
                }
            },
            {
                path: "cards",
                name: "finance.cards.index",
                component: () => import("@/views/billing/card/Index.vue"),
                meta: {
                    auth: true,
                    label: "Cards",
                    location: "sidebar",
                    parent: "finance",
                    permission: "manage-customer-credit-cards"
                }
            }
        ],
        meta: {
            name: "finance",
            auth: true,
            label: "Finance",
            location: "sidebar-top",
            group: "main",
            icon: "fal fa-landmark fa-fw"
        }
    },
    {
        path: "/kb",
        name: "knowledgebase.index",
        component: () => import("@/views/university/knowledgebase/Index.vue"),
        meta: {
            label: "Knowledge Base"
        }
    },
    {
        path: "/cis",
        name: "cis.index",
        component: () => import("@/views/cis/Index.vue"),
        meta: {
            label: "Critical Information Summary"
        }
    },
    {
        path: "/legals",
        name: "knowledgebase.legals",
        component: () => import("@/views/university/knowledgebase/Legals.vue"),
        meta: {
            label: "Legals"
        }
    },
    {
        path: "/university",
        name: "university",
        component: () => import("@/views/university/Index.vue"),
        children: [
            {
                path: "kb",
                name: "university.knowledgebase.index",
                component: () => import("@/views/university/knowledgebase/Index.vue"),
                meta: {
                    auth: true,
                    label: "Knowledge Base",
                    location: "sidebar",
                    parent: "university"
                }
            },
            {
                path: "private-kb",
                name: "university.knowledgebase.private",
                component: () => import("@/views/university/knowledgebase/Private.vue"),
                meta: {
                    auth: true,
                    label: "Private KB",
                    title: "Private Knowledge Base",
                    location: "sidebar",
                    parent: "university"
                }
            }
            // {
            //     path: 'manuals',
            //     name: 'billing.manuals.index',
            //     component: () => import('@/views/billing/Pay.vue'),
            //     meta: {
            //         auth: true,
            //         label: 'Manuals',
            //         location: 'sidebar',
            //         parent: 'university',
            //     }
            // },
            // {
            //     path: 'procedures',
            //     name: 'billing.procedures.index',
            //     component: () => import('@/views/billing/Pay.vue'),
            //     meta: {
            //         auth: true,
            //         label: 'Procedures',
            //         location: 'sidebar',
            //         parent: 'university',
            //     }
            // }
        ],
        meta: {
            name: "university",
            auth: true,
            label: "University",
            location: "sidebar-top",
            group: "main",
            icon: "fal fa-graduation-cap fa-fw"
        }
    },
    {
        path: "/settings",
        name: "settings",
        component: () => import("@/views/settings/Index.vue"),
        children: [
            {
                path: "profile",
                name: "settings.profile",
                component: () => import("@/views/settings/Profile.vue"),
                meta: {
                    auth: true,
                    label: "Profile",
                    icon: "fal fa-user-circle fa-fw",
                    group: "settings",
                    location: "sidebar"
                }
            },
            {
                path: "security",
                name: "settings.security",
                component: () => import("@/views/settings/Security.vue"),
                meta: {
                    auth: true,
                    label: "Security",
                    icon: "fal fa-shield-check fa-fw",
                    group: "settings",
                    location: "sidebar"
                }
            }
        ],
        meta: {
            auth: true,
            label: "Settings",
            location: "avatar",
            icon: "fal fa-user-cog fa-fw",
            group: "settings"
        }
    },
    {
        path: "/articles/:id",
        name: "articles.show",
        component: () => import("@/views/article/Show.vue"),
        meta: {
            //guest: true,
        }
    },
    {
        path: "/mobile_coverage",
        name: "mobile_coverage",
        component: () => import("@/views/MobileCoverage.vue"),
        meta: {
            label: "Mobile Coverage"
            //guest: true,
        }
    },
    {
        path: "/pay",
        name: "pay",
        component: () => import("@/views/QuickPay.vue"),
        meta: {
            label: "Quick Pay"
            //guest: true,
        }
    },
    {
        path: "/tariffs/:id",
        name: "tariffs.show",
        component: () => import("@/views/tariffs/Show.vue"),
        meta: {
            title: "Tariffs"
        }
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/Login.vue"),
        meta: {
            guest: true,
            label: "Login"
        }
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import("@/views/ForgotPassword.vue"),
        meta: {
            guest: true,
            label: "Forgot password"
        }
    },
    {
        path: "/password/reset/:token",
        name: "reset-password",
        component: () => import("@/views/ResetPassword.vue"),
        meta: {
            guest: true,
            label: "Reset password"
        }
    },
    {
        path: "*",
        redirect: { name: "dashboard" },
        meta: {}
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                selector: to.hash
            };
        }

        return { x: 0, y: 0 };
    }
});

router.beforeEach((to, from, next) => {
    const { authenticated, me } = Store.getters;
    const requiresAuth = to.matched.some(record => record.meta.auth);
    const requiresGuest = to.matched.some(record => record.meta.guest);

    document.title = `${to.meta?.title || to.meta?.label || to.query?.title} - ${
        process.env.VUE_APP_NAME
    }`;

    Vue.nextTick(() => {
        if (to.name === "login" && to.query.token) {
            next();
            return;
        }

        if (!authenticated) {
            router.app.$loading.open();
            Http.get("me")
                .then(({ data }) => {
                    const me = data.data;

                    router.app.$loading.close();
                    Store.commit("login", me);

                    if (requiresGuest) {
                        next({ name: "dashboard" });
                    }

                    if (
                        (requiresAuth &&
                            ((Array.isArray(me.permissions) &&
                                me.permissions.map(p => p.name).includes(to.meta?.permission)) ||
                                (Array.isArray(me.roles) &&
                                    me.roles.map(p => p.name).includes("super-admin")) ||
                                typeof to.meta?.permission === "undefined")) ||
                        (!requiresAuth && !requiresGuest)
                    ) {
                        next();
                    }

                    if (
                        requiresAuth &&
                        Array.isArray(me.permissions) &&
                        Array.isArray(me.roles) &&
                        me.permissions.map(p => p.name).includes(to.meta?.permission) === false &&
                        typeof to.meta?.permission !== "undefined" &&
                        me.roles.map(role => role.name).includes("super-admin") === false
                    ) {
                        next({ name: "dashboard" });
                    }
                })
                .catch(() => {
                    router.app.$loading.close();

                    if (requiresAuth) {
                        next({ name: "login" });
                        return;
                    }

                    next();
                });
        } else if (
            authenticated &&
            (requiresGuest ||
                (requiresAuth &&
                    Array.isArray(me.permissions) &&
                    Array.isArray(me.roles) &&
                    me.permissions.map(p => p.name).includes(to.meta?.permission) === false &&
                    typeof to.meta?.permission !== "undefined" &&
                    me.roles.map(role => role.name).includes("super-admin") === false))
        ) {
            next({ name: "dashboard" });
        } else {
            next();
        }
    });
});

export default router;
