import axios from "axios";
import qs from "qs";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const { location } = window;
const pieces = location.hostname.split(".");

pieces.shift();

const config = {
    baseURL: `https://${process.env.VUE_APP_API_URL_PREFIX}.${pieces.join(".")}${
        process.env.VUE_APP_API_URL_PORT
    }`,
    timeout: 60 * 1000, // Timeout
    withCredentials: true // Check cross-site Access-Control
};

const httpClient = axios.create(config);

export function setupInterceptors(app: Vue) {
    httpClient.interceptors.request.use(
        config => {
            config.paramsSerializer = params => qs.stringify(params);
            return config;
        },
        error => Promise.reject(error)
    );

    // Add a response interceptor
    httpClient.interceptors.response.use(
        response => response,
        error => {
            const {
                response: { status }
            } = error;
            const hidden = [422, 401, 419];

            if (hidden.indexOf(status) === -1) {
                app.$snackbar.open({
                    text: error.response.data.message || error.response.data.exception,
                    type: "error",
                    timeout: 0
                });
            }

            if (status === 419) {
                return httpClient.get("csrf-cookie").then(
                    () =>
                        new Promise((resolve, reject) => {
                            httpClient
                                .request(error.response.config)
                                .then(response => resolve(response))
                                .catch(error => reject(error));
                        })
                );
            }

            return Promise.reject(error);
        }
    );
}

export default httpClient;
